import React, { Component, useRef } from 'react';
import * as ReactDOM from "react-dom";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { Dropdown, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useEffect } from "react";
import { settoken } from "../features/WhiseTokenSlice";
import { useSelector, useDispatch } from "react-redux";
import { useState } from 'react';
import axios from 'axios';
import { Datepicker, DatepickerEvent } from "@meinefinsternis/react-horizontal-date-picker";
import { EventModal } from "./EventModal";
import { variables } from '../Variables';
import { clearMailVerificationDetail } from "../features/EmailVerificationSlice";
import { Footer } from './Footer'

import propMainImg from '../assets/images/Rectangle_68.svg';
import logo from '../assets/images/logo.svg';
import locationPin from '../assets/images/location_pin.svg';
import phone from '../assets/images/phone.svg';
import bed from '../assets/images/bed.svg';
import p_shower from '../assets/images/p_shower.svg';
import lift from '../assets/images/lift.svg';
import p_img_1 from '../assets/images/prop_img_1.svg';
import p_img_2 from '../assets/images/prop_img_2.svg';
import p_img_3 from '../assets/images/prop_img_3.svg';
import p_img_4 from '../assets/images/prop_img_4.svg';
import phoneCall from '../assets/images/PhoneCall.svg';
import bathtub from '../assets/images/Bathtub.svg';
import shower from '../assets/images/Shower.svg';
import wifiHigh from '../assets/images/WifiHigh.svg';
import televisionSimple from '../assets/images/TelevisionSimple.svg';
import coffee from '../assets/images/Coffee.svg';
import megaphone from '../assets/images/megaphone.svg';
import closeIcon from '../assets/images/cross-close.svg';
import languageDropdownIcon from '../assets/images/language-icon.svg';
import websiteIcon from '../assets/images/website_img.svg';
import angleLeft from '../assets/images/angle-left-small_black.svg';
import angleRight from '../assets/images/angle-right-small_black.svg';

const localeMapping = {
    "en-GB": "en-GB", "fr-BE": "fr", "nl-BE": "nl-BE", "zu-ZA": "", "zh-Hant-TW": "", "zh-Hans-SG": "", "zh-Hans-MO": "", "zh-Hans-HK": "", "zh-Hans-CN": "", "zgh-MA": "", "yo-NG": "",
    "yo-BJ": "", "xh-ZA": "", "wo-SN": "", "vi-VN": "", "uz-Latn-UZ": "", "uz-Cyrl-UZ": "", "ur-PK": "", "ur-IN": "", "uk-UA": "", "ug-CN": "", "tt-RU": "", "tr-TR": "", "tr-CY": "", "tk-TM": "",
    "ti-ET": "", "ti-ER": "", "th-TH": "", "tg-TJ": "", "te-IN": "", "ta-SG": "", "ta-MY": "", "ta-LK": "", "ta-IN": "", "sw-UG": "", "sw-TZ": "", "sw-KE": "", "sw-CD": "", "sv-SE": "", "sv-FI": "",
    "sr-Latn-XK": "", "sr-Latn-RS": "", "sr-Latn-ME": "", "sr-Latn-BA": "", "sr-Cyrl-XK": "", "sr-Cyrl-RS": "", "sr-Cyrl-ME": "", "sr-Cyrl-BA": "", "sq-XK": "", "sq-MK": "", "sq-AL": "", "so-SO": "",
    "so-KE": "", "so-ET": "", "so-DJ": "", "sn-ZW": "", "sl-SI": "", "sk-SK": "", "si-LK": "", "se-SE": "", "se-NO": "", "se-FI": "", "sd-PK": "", "sah-RU": "", "ru-UA": "", "ru-RU": "", "ru-MD": "",
    "ru-KZ": "", "ru-KG": "", "ru-BY": "", "ro-RO": "", "ro-MD": "", "rm-CH": "", "pt-TL": "", "pt-ST": "", "pt-PT": "", "pt-MZ": "", "pt-MO": "", "pt-LU": "", "pt-GW": "", "pt-GQ": "", "pt-CV": "",
    "pt-CH": "", "pt-BR": "", "pt-AO": "", "ps-PK": "", "ps-AF": "", "pl-PL": "", "pa-Guru-IN": "", "pa-Arab-PK": "", "or-IN": "", "om-KE": "", "om-ET": "", "nn-NO": "", "nl-SX": "", "nl-SR": "",
    "nl-NL": "", "nl-CW": "", "nl-BQ": "", "nl-AW": "", "ne-NP": "", "ne-IN": "", "nb-SJ": "", "nb-NO": "", "my-MM": "", "mt-MT": "", "ms-SG": "", "ms-MY": "", "ms-BN": "", "mr-IN": "", "mn-MN": "",
    "ml-IN": "", "mk-MK": "", "mi-NZ": "", "mg-MG": "", "lv-LV": "", "lt-LT": "", "lb-LU": "", "ku-TR": "", "kok-IN": "", "ko-KR": "", "ko-KP": "", "kn-IN": "", "km-KH": "", "kk-KZ": "", "ka-GE": "",
    "jv-ID": "", "ja-JP": "", "it-IT": "", "it-CH": "", "is-IS": "", "ii-CN": "", "ig-NG": "", "id-ID": "", "hy-AM": "", "hu-HU": "", "hsb-DE": "", "hr-HR": "", "hi-IN": "", "he-IL": "", "gu-IN": "",
    "gsw-CH": "", "gl-ES": "", "gd-GB": "", "ga-IE": "", "fy-NL": "", "fr-YT": "", "fr-WF": "", "fr-VU": "", "fr-TN": "", "fr-TG": "", "fr-TD": "", "fr-SY": "", "fr-SN": "", "fr-SC": "", "fr-RW": "",
    "fr-RE": "", "fr-PM": "", "fr-PF": "", "fr-NE": "", "fr-NC": "", "fr-MU": "", "fr-MR": "", "fr-MQ": "", "fr-ML": "", "fr-MG": "", "fr-MF": "", "fr-MC": "", "fr-MA": "", "fr-LU": "", "fr-KM": "",
    "fr-HT": "", "fr-GQ": "", "fr-GP": "", "fr-GN": "", "fr-GF": "", "fr-GA": "", "fr-FR": "", "fr-DZ": "", "fr-DJ": "", "fr-CM": "", "fr-CI": "", "fr-CH": "", "fr-CG": "", "fr-CF": "", "fr-CD": "",
    "fr-CA": "", "fr-BL": "", "fr-BJ": "", "fr-BI": "", "fr-BF": "", "fo-FO": "", "fo-DK": "", "fil-PH": "", "fi-FI": "", "fa-IR": "", "fa-AF": "", "eu-ES": "", "et-EE": "", "es-VE": "", "es-UY": "",
    "es-US": "", "es-SV": "", "es-PY": "", "es-PR": "", "es-PH": "", "es-PE": "", "es-PA": "", "es-NI": "", "es-MX": "", "es-IC": "", "es-HN": "", "es-GT": "", "es-GQ": "", "es-ES": "", "es-EC": "",
    "es-EA": "", "es-DO": "", "es-CU": "", "es-CR": "", "es-CO": "", "es-CL": "", "es-BZ": "", "es-BR": "", "es-BO": "", "es-AR": "", "en-ZW": "", "en-ZM": "", "en-ZA": "", "en-WS": "", "en-VU": "",
    "en-VI": "", "en-VG": "", "en-VC": "", "en-US": "", "en-UM": "", "en-UG": "", "en-TZ": "", "en-TV": "", "en-TT": "", "en-TO": "", "en-TK": "", "en-TC": "", "en-SZ": "", "en-SX": "", "en-SS": "",
    "en-SL": "", "en-SI": "", "en-SH": "", "en-SG": "", "en-SE": "", "en-SD": "", "en-SC": "", "en-SB": "", "en-RW": "", "en-PW": "", "en-PR": "", "en-PN": "", "en-PK": "", "en-PH": "", "en-PG": "",
    "en-NZ": "", "en-NU": "", "en-NR": "", "en-NL": "", "en-NG": "", "en-NF": "", "en-NA": "", "en-MY": "", "en-MW": "", "en-MU": "", "en-MT": "", "en-MS": "", "en-MP": "", "en-MO": "", "en-MH": "",
    "en-MG": "", "en-LS": "", "en-LR": "", "en-LC": "", "en-KY": "", "en-KN": "", "en-KI": "", "en-KE": "", "en-JM": "", "en-JE": "", "en-IO": "", "en-IN": "", "en-IM": "", "en-IL": "", "en-IE": "",
    "en-HK": "", "en-GY": "", "en-GU": "", "en-GM": "", "en-GI": "", "en-GH": "", "en-GG": "", "en-GD": "", "en-FM": "", "en-FK": "", "en-FJ": "", "en-FI": "", "en-ER": "", "en-DM": "", "en-DK": "",
    "en-DE": "", "en-CY": "", "en-CX": "", "en-CM": "", "en-CK": "", "en-CH": "", "en-CC": "", "en-CA": "", "en-BZ": "", "en-BW": "", "en-BS": "", "en-BM": "", "en-BI": "", "en-BE": "", "en-BB": "",
    "en-AU": "", "en-AT": "", "en-AS": "", "en-AI": "", "en-AG": "", "en-AE": "", "el-GR": "", "el-CY": "", "de-LU": "", "de-LI": "", "de-IT": "", "de-DE": "", "de-CH": "", "de-BE": "", "de-AT": "",
    "da-GL": "", "da-DK": "", "cy-GB": "", "cs-CZ": "", "ca-ES": "", "br-FR": "", "bo-IN": "", "bo-CN": "", "bn-IN": "", "bn-BD": "", "bg-BG": "", "be-BY": "", "as-IN": "", "ar-YE": "", "ar-TN": "",
    "ar-TD": "", "ar-SY": "", "ar-SS": "", "ar-SO": "", "ar-SD": "", "ar-SA": "", "ar-QA": "", "ar-PS": "", "ar-OM": "", "ar-MR": "", "ar-MA": "", "ar-LY": "", "ar-LB": "", "ar-KW": "", "ar-KM": "",
    "ar-JO": "", "ar-IQ": "", "ar-IL": "", "ar-ER": "", "ar-EH": "", "ar-EG": "", "ar-DZ": "", "ar-DJ": "", "ar-BH": "", "ar-AE": "", "am-ET": "", "af-ZA": "", "af-NA": "",
};

export const Home = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 991);
    const [mounted, setMounted] = useState(false);
    const ref = useRef(null);
    const [calendarActions, setCalendarActions] = useState([]);
    const [clientEstates, setClientEstates] = useState([]);
    const [selectedEstate, setSelectedEstate] = useState(null);
    const [whiseCalendarEvents, setWhiseCalendarEvents] = useState([]);
    const [clientToken, setClientToken] = useState("");
    const [confirmedIds, setConfirmedIds] = useState([]);
    const [tobeConfirmedIds, setTobeConfirmedIds] = useState([]);
    const [freeSlotIds, setFreeSlotIds] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [events, setEvents] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [clientSettings, setClientSettings] = useState(null);
    const [whiseOffice, setWhiseOffice] = useState(null);
    const [xCoordinate, setXCoordinate] = useState("");
    const [yCoordinate, setYCoordinate] = useState("");
    const [mapUrl, setMapUrl] = useState("");
    const [time, setTime] = useState("");
    const [estatePurpose, setEstatePurpose] = useState(null);
    const [isAnyDateSelected, setIsAnyDateSelected] = useState(false);
    const [formStep, setFormStep] = useState(1);
    const [prevSelectedEventSlot, setPrevSelectedEventSlot] = useState(null);
    const [announcementMessage, setAnnouncementMessage] = useState("");
    const [locale, setLocale] = useState(null);
    const [showButtonLoader, setShowButtonLoader] = useState(false);

    const [allLanguageForDropwon, setAllLanguageForDropwon] = useState([]);
    const [translationsForAllLanguages, setTranslationsForAllLanguages] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState("en-GB");
    const [selectedLanguageTranslations, setSelectedLanguageTranslations] = useState(null);
    const [agentLanguageTranslation, setAgentLanguageTranslation] = useState(null);
    const [slotAvailable, setSlotAvailable] = useState([]);

    let purpose = [{ "id": 1, "name": "for sale" }, { "id": 2, "name": "for rent" }, { "id": 3, "name": "life annuity sale" }];

    let startValueForDateSlider = new Date();
    let firstMatchFound = false;
    let eventExist = false;
    const defaultLanguage = "en-GB";

    const [date, setDate] = useState(startValueForDateSlider);

    var days = ['sun', 'mon', 'tue', 'wed', 'thur', 'fri', 'sat'];//['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];

    const monthNames = ["january", "february", "march", "april", "may", "june",
        "july", "august", "september", "october", "november", "december"
    ];

    const { propertyId, clientId, officeId, contactId, languageId } = useParams();
    const whiseUserToken = useSelector(state => state.whiseUser.whiseToken);
    const dispatch = useDispatch();


    useEffect(() => {
        // Get the browser's primary language
        if (!languageId) {
            const browserLanguage = defaultLanguage; //navigator.language || navigator.userLanguage;
            //const browserLanguage = navigator.language || navigator.userLanguage;tLocale
            setSelectedLanguage(browserLanguage);
        }
        else {
            setSelectedLanguage(languageId);
        }
    }, [languageId]);

    useEffect(() => {
        if (selectedLanguage && translationsForAllLanguages) {
            const translationsForLanguage = translationsForAllLanguages[selectedLanguage];
            setSelectedLanguageTranslations(translationsForLanguage);

            const loadLocale = async () => {
                const localeKey = localeMapping[selectedLanguage];
                if (localeKey) {
                    const importedLocale = await import(`date-fns/locale/${localeKey}/index.js`);
                    setLocale(importedLocale.default);
                } else {
                    console.warn(`Locale for ${selectedLanguage} not found. Falling back to default.`);
                    setLocale(null); // Fallback to no locale
                }
            };
            loadLocale();

            //if (slotAvailable.length > 0) {
            //    setAnnouncementMessage(translationsForLanguage != null ? `${translationsForLanguage["slotBookingAnnouncementSuitableDateTimeLabel"]}` : "Pick a slot that will suits you on");
            //} else {
            //    setAnnouncementMessage(translationsForLanguage != null ? `${translationsForLanguage["slotBookingQuicklyAnnouncementSuitableDateTimeLabel"]}` : "Quickly book the next slot");
            //}
        }
    }, [selectedLanguage, translationsForAllLanguages])

    const getLanguageTranslations = () => {
        const jsonconfig = {
            headers: {
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Settings/GetTranslationsForSiteByLang?siteName=booking`;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let translations = response.data;
                if (Object.keys(translations).length > 0) {
                    setTranslationsForAllLanguages(translations);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const handleClose = () => {
        setPrevSelectedEventSlot(selectedSlot);
        setShowModal(false);
        setFormStep(1);
    };

    const handlerBookSlot = async () => {
        setShowButtonLoader(true);
        let body = {}
        if (selectedSlot != null) {
            //setFormStep(1);
            //setShowModal(true);
            dispatch(clearMailVerificationDetail());
            body.CalendarIds = [+selectedSlot.id];
            body.IncludeRecurrences = true;

            if (selectedSlot.isLocalDbEvent) {
                await getLocalCalenderEventById(selectedSlot.id);
            }
            else {
                await getWhiseCalenderEventById(body);
            }

            if (eventExist) {
                setShowModal(true);
            } else {
                //alert("This Slot is already booked and not available please try an Other slot")
                alert(selectedLanguageTranslations != null ? selectedLanguageTranslations["alertSlotAlreadyBooked"] : "This Slot is already booked and not available please try an Other slot");
            }
            setShowButtonLoader(false);
        }
        else {
            setShowButtonLoader(false);
            alert(selectedLanguageTranslations != null ? selectedLanguageTranslations["alertFreeSlotChoose"] : "please choose a slot");
        }
    }

    const getWhiseCalenderEventById = async (filters) => {
        let url = "https://api.whise.eu/v1/calendars/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };
        let body = {
            "Filter": filters
        };
        await axios.post(url, body, config)
            .then(response => {
                if (response.data.calendars) {
                    eventExist = true;
                } else {
                    eventExist = false;
                }
            })
            .catch(error => {
                console.log("error", error);
            });
    }

    useEffect(() => {
        if (propertyId) {
            getToken();
            getAllLanguage();
            getLanguageTranslations();
        }
    }, [propertyId, date]);

    const getToken = async () => {
        let url = "https://api.whise.eu/token";
        let headers = {
            'Content-Type': 'application/json'
        };
        let body = {
            "Username": variables.WhiseUser,
            "Password": variables.WhisePassword
        };

        try {
            let resp = await axios.post(url, body,
                {
                    headers: headers
                });
            if (resp && resp.data && resp.data.token) {
                dispatch(settoken(resp.data.token));
            }
        }
        catch (e) {
            console.log(e);
        }
    }

    const getAllLanguage = () => {
        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Settings/GetAllLanguages`;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let allLanguage = response.data;
                setAllLanguageForDropwon(allLanguage);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const onChangeSelectLanguage = (e) => {
        const selectedIndex = e.target.selectedIndex;
        const selectedText = e.target.options[selectedIndex].text;
        setSelectedLanguage(selectedText);
    }

    useEffect(() => {
        if (whiseUserToken != null) {
            getClientToken();
            getClientSettings();
            getWhiseOffices();
        }
    }, [whiseUserToken])

    const getClientToken = () => {
        let url = "https://api.whise.eu/v1/admin/clients/token";

        const config = {
            headers: {
                'Authorization': `Bearer ${whiseUserToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, { "clientId": +clientId }, config)
            .then(response => {
                setClientToken(response.data.token);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getClientSettings = () => {
        let url = 'https://api.whise.eu/v1/admin/clients/settings';

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${whiseUserToken}`
        };

        let body = {
            ClientId: clientId
        };

        axios.post(url, body,
            {
                headers: headers
            })
            .then(response => {
                setClientSettings(response.data.settings);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        if (clientSettings && translationsForAllLanguages) {
            if (clientSettings.defaultLanguageId) {
                //const agentDefualtLanguage = "fr-BE"; // nl-BE en-GB
                //const translationsAgentLanguage = translationsForAllLanguages[agentDefualtLanguage];
                const translationsAgentLanguage = translationsForAllLanguages[clientSettings.defaultLanguageId];
                setAgentLanguageTranslation(translationsAgentLanguage);
            }
        }
    }, [clientSettings, translationsForAllLanguages])

    const getWhiseOffices = () => {
        let url = "https://api.whise.eu/v1/admin/offices/list";

        const config = {
            headers: {
                'Authorization': `Bearer ${whiseUserToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, { "clientId": clientId }, config)
            .then(response => {
                if (response.data.offices != undefined) {
                    var _filteredOffice = response.data.offices.filter((item) => { return item.id == officeId });
                    setWhiseOffice(_filteredOffice[0]);
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        if (selectedLanguage && clientToken) {
            getClientWhiseEstatesList();
        }
    }, [clientToken, selectedLanguage])

    const getClientWhiseEstatesList = () => {
        let url = "https://api.whise.eu/v1/estates/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, {
            "Filter": {
                "StatusIds": [1],
                "PurposeStatusIds": [1, 2, 5, 6, 10, 11, 12, 13, 15, 16, 20, 21, 27, 28],
                "DisplayStatusIds": [1, 2, 3, 4, 5],
                "EstateIds": [+propertyId],
                "LanguageId": selectedLanguage
            }
        }, config)
            .then(response => {
                setClientEstates(response.data.estates);
            })
            .catch(error => {
                console.log("error", error);
            });
    }

    useEffect(() => {
        if (clientEstates != undefined && clientEstates.length > 0) {
            getCalendarActions();
            let filteredEstate = clientEstates.filter(item => {
                return item.id == propertyId
            })
            if (filteredEstate[0].details != undefined && filteredEstate[0].details.length > 0) {
                filteredEstate[0].details.forEach(item => {
                    if (item.id == 1849) {
                        setXCoordinate(item.value);
                    }
                    if (item.id == 1850) {
                        setYCoordinate(item.value);
                    }
                })

            }
            setSelectedEstate(filteredEstate[0]);
        }
    }, [clientEstates])

    useEffect(() => {
        if (selectedEstate != null) {
            //setEstatePurpose

            let estatePurpose = purpose.filter(item => {
                return item.id == selectedEstate.purpose.id
            })

            if (estatePurpose.length > 0) {
                setEstatePurpose(estatePurpose[0]);
            }
        }
    }, [selectedEstate])

    const getCalendarActions = () => {
        let url = "https://api.whise.eu/v1/calendars/actions/list";

        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        axios.post(url, {}, config)
            .then(response => {
                setCalendarActions(response.data.calendarActions);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    useEffect(() => {
        if (calendarActions.length > 0) {
            getOfficeSettings();
        }
    }, [calendarActions])

    const getOfficeSettings = () => {
        const jsonconfig = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `Settings/GetLocalSetting?clientId=` + clientId;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                let settings = response.data;
                let toBeConfirmed = [settings.visitToSaleBefore, settings.visitToRentBefore];
                let cofirmed = [settings.visitToSaleAfter, settings.visitToRentAfter];
                let freeslot = [settings.visitToSaleFreeSlot, settings.visitToRentFreeSlot];

                setTobeConfirmedIds(toBeConfirmed);
                setConfirmedIds(cofirmed);
                setFreeSlotIds(freeslot);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }

    const getWhiseCalenderEvents = async (calendarActionIds) => {
        var eventLimit = 300;
        var pageOffset = 0;
        let listOfEvents = [];

        let _date = new Date();

        let firstDay = new Date(_date.getFullYear(), _date.getMonth(), 1);
        firstDay.setHours(0, 0, 0, 0);
        firstDay.setMinutes(firstDay.getMinutes() - firstDay.getTimezoneOffset());
        firstDay.setMonth(firstDay.getMonth() + 0);

        let isoString = firstDay.toISOString(); // Convert to ISO string
        let startDate = isoString.slice(0, 19); // Remove milliseconds and the 'Z'

        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        lastDay.setHours(23, 59, 59, 0);
        lastDay.setMinutes(lastDay.getMinutes() - lastDay.getTimezoneOffset());
        //lastDay.setDate(lastDay.getDate() + 1);
        //lastDay.setSeconds(lastDay.getSeconds() - 1);
        lastDay.setMonth(lastDay.getMonth() + 1);

        let isoStringEnd = lastDay.toISOString(); // Convert to ISO string
        let endDate = isoStringEnd.slice(0, 19); // Remove milliseconds and the 'Z'
        //let endDate = lastDay.toISOString();

        let _response = await getWhiseCalendarEventsByPagination(clientToken, eventLimit, pageOffset, startDate, endDate);

        //get free slots saved in Database
        let responseLocalDBEvents = await getCalendarEventsFromDB();
        if (responseLocalDBEvents.totalCount != undefined && responseLocalDBEvents.totalCount > 0) {
            listOfEvents.push(...responseLocalDBEvents.calendars);
            console.log("localEvent", responseLocalDBEvents.calendars[0]);
        }

        if (_response.totalCount != undefined && _response.totalCount > 300) {
            listOfEvents.push(..._response.calendars);
            let _afterDivison = _response.totalCount / 300;
            let pages = Math.ceil(_afterDivison);
            for (let i = 1; i < pages; i++) {
                pageOffset = eventLimit * i + 1;
                let _eventResponse = await getWhiseCalendarEventsByPagination(clientToken, eventLimit, pageOffset, startDate, endDate);
                if (_eventResponse.calendars != undefined && _eventResponse.calendars.length > 0) {
                    listOfEvents.push(..._eventResponse.calendars);
                }
            }

            let mappedActions = listOfEvents.filter(item => {
                return item.estates != undefined && item.estates[0].estateId == propertyId && calendarActionIds.indexOf(item.action.id) != -1;
            })
                .sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));

            setWhiseCalendarEvents(mappedActions);

            //check if free slot events exist for selected day
            let SelectedDate = date;
            let currentSelectedDateEvents = mappedActions.filter(item => {
                return new Date(item.startDateTime).getDate() == SelectedDate.getDate() && new Date(item.startDateTime) >= SelectedDate;
            })
            setSlotAvailable(currentSelectedDateEvents);
            //update announcement message if free slot event exist for selected day
            //if (currentSelectedDateEvents.length > 0) {
            //    setAnnouncementMessage(`${selectedLanguageTranslations != null ? selectedLanguageTranslations["slotBookingAnnouncementSuitableDateTimeLabel"] : "Pick a slot that will suits you on"}`);
            //}
            //if (currentSelectedDateEvents.length > 0) {
            //    setAnnouncementMessage(selectedLanguageTranslations != null ? `${selectedLanguageTranslations["slotBookingAnnouncementSuitableDateTimeLabel"]}` : "Pick a slot that will suits you on");
            //} else {
            //    setAnnouncementMessage(selectedLanguageTranslations != null ? `${selectedLanguageTranslations["slotBookingQuicklyAnnouncementSuitableDateTimeLabel"]}` : "Quickly book the next slot");
            //}
        }
        else if (_response.calendars != undefined && _response.calendars.length > 0) {
            listOfEvents.push(..._response.calendars);
            let mappedActions = listOfEvents.filter(item => {
                return item.estates != undefined && item.estates[0].estateId == propertyId && calendarActionIds.indexOf(item.action.id) != -1;
            })
                .sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));

            setWhiseCalendarEvents(mappedActions);

            //check if free slot events exist for selected day
            let SelectedDate = date;
            let currentSelectedDateEvents = mappedActions.filter(item => {
                return new Date(item.startDateTime).getDate() == SelectedDate.getDate() && new Date(item.startDateTime) >= SelectedDate;
            })
            setSlotAvailable(currentSelectedDateEvents);
            //update announcement message if free slot event exist for selected day
            //if (currentSelectedDateEvents.length > 0) {
            //    setAnnouncementMessage(`${selectedLanguageTranslations != null ? selectedLanguageTranslations["slotBookingAnnouncementSuitableDateTimeLabel"] : "Pick a slot that will suits you on"}`);
            //}
            //if (currentSelectedDateEvents.length > 0) {
            //    setAnnouncementMessage(selectedLanguageTranslations != null ? `${selectedLanguageTranslations["slotBookingAnnouncementSuitableDateTimeLabel"]}` : "Pick a slot that will suits you on");
            //} else {
            //    setAnnouncementMessage(selectedLanguageTranslations != null ? `${selectedLanguageTranslations["slotBookingQuicklyAnnouncementSuitableDateTimeLabel"]}` : "Quickly book the next slot");
            //}
        }
        else {
            setWhiseCalendarEvents([]);
        }
    }

    const getWhiseCalendarEventsByPagination = async (clientToken, eventLimit, pageOffset, startDate, endDate) => {

        let url = "https://api.whise.eu/v1/calendars/list";
        const config = {
            headers: {
                'Authorization': `Bearer ${clientToken}`,
                'Content-Type': 'application/json'
            },
        };

        let body = {
            "Filter": {
                "DateTimeRange": {
                    "Min": startDate,
                    "Max": endDate
                },
                "IncludeRecurrences": true
            },
            "Page": {
                "Limit": eventLimit,
                "Offset": pageOffset
            }
        };
        let response = await axios.post(url, body, config);

        return response.data
    }

    const getCalendarEventsFromDB = async () => {
        let url = variables.API_URL + `LocalEvents/GetLocalEventsForBookingSite?clientId=${clientId}&officeId=${officeId}&estateId=${propertyId}`;
        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
        };

        let response = await axios.post(url, {}, config);

        return response.data;
    }

    const getStatus = (actionId) => {
        if (tobeConfirmedIds.indexOf(actionId) !== -1) {
            return "To Be Confirmed";
        }
        else if (confirmedIds.indexOf(actionId) !== -1) {
            return "Confirmed";
        } else if (freeSlotIds.indexOf(actionId) !== -1) {
            return "Free Slot";
        } else {
            return '';
        }
    }

    const getAction = (actionId) => {
        let action = calendarActions.filter((d) => d.id === actionId);
        return action[0].name;
    }

    const onSelectedDay = (d) => {
        const [startValue, selectedDate] = d;
        if (startValue == null && selectedDate == null) {
            return;
        }
        let isBackward = false;
        if (date.getDate() == selectedDate.getDate()) {
            isBackward = true;
        }
        else {
            isBackward = false;
        }
        let clickedDate = 0;
        if (isBackward == true) {
            clickedDate = startValue.getDate() < 10 ? "0" + startValue.getDate() : startValue.getDate();
        }
        else {
            clickedDate = selectedDate.getDate() < 10 ? "0" + selectedDate.getDate() : selectedDate.getDate();
        }
        //let clickedDate = isBackward == true ? (startValue.getDate() < 10 ? "0" + startValue.getDate() : startValue.getDate()) : (selectedDate.getDate() < 10 ? "0" + selectedDate.getDate() : selectedDate.getDate());

        let month = selectedLanguageTranslations[monthNames[isBackward == true ? startValue.getMonth() : selectedDate.getMonth()]]; //monthNames[isBackward == true ? startValue.getMonth() : selectedDate.getMonth()];
        let slotsList = document.getElementsByClassName(month + clickedDate);
        let elementClassList = slotsList.length > 0 ? document.getElementsByClassName(month + clickedDate)[0].classList : "";

        if (elementClassList == "" || (elementClassList != "" && [...elementClassList].includes('has-event') == false)) {
            return;
        }
        setIsAnyDateSelected(true);

        let currenDateTime = new Date();
        if (startValue < date) {
            if (startValue.getDate() == currenDateTime.getDate()) {
                startValue.setHours(currenDateTime.getHours())
                startValue.setMinutes(currenDateTime.getMinutes())// - currenDateTime.getTimezoneOffset());
            }
            setDate(startValue)
        }
        else {
            if (selectedDate.getDate() == currenDateTime.getDate()) {
                selectedDate.setHours(currenDateTime.getHours())
                selectedDate.setMinutes(currenDateTime.getMinutes())// - currenDateTime.getTimezoneOffset());
            }
            setDate(selectedDate)
        }
        setSelectedSlot(null);

        let dateFilter = isBackward == true ? startValue : selectedDate;

        //check if free slot events exist for selected day
        let currentSelectedDateEvents = whiseCalendarEvents.filter(item => {
            return new Date(item.startDateTime).getDate() == dateFilter.getDate() && new Date(item.startDateTime) >= dateFilter;
        })
        setSlotAvailable(currentSelectedDateEvents);
        //update announcement message if free slot event exist for selected day
        //if (currentSelectedDateEvents.length > 0) {
        //    setAnnouncementMessage(selectedLanguageTranslations != null ? selectedLanguageTranslations["slotBookingAnnouncementSuitableDateTimeLabel"] : "Pick a slot that will suits you on");
        //}
        //else {
        //    setAnnouncementMessage(selectedLanguageTranslations != null ? selectedLanguageTranslations["slotBookingQuicklyAnnouncementSuitableDateTimeLabel"] : "Quickly book the next slot");
        //}
    }

    const scrollLeft = () => {
        if (ref.current) {
            const container = ref.current && Array.from(ref.current.children).find(child => child.classList.contains('dr')); // Adjust the selector
            container.scrollBy({ left: -container.offsetWidth, behavior: 'smooth' });
        }
    };

    const scrollRight = () => {
        if (ref.current) {
            const container = ref.current && Array.from(ref.current.children).find(child => child.classList.contains('dr')); // Adjust the selector
            container.scrollBy({ left: container.offsetWidth, behavior: 'smooth' });
        }
    };

    const handlerSelectedSlot = async (e) => {
        setTime(e.target.innerHTML);
        let dateItem = JSON.parse(e.target.getAttribute("event"));

        let dataItem = JSON.parse(e.target.getAttribute("event"));
        setSelectedSlot(dataItem);

        //let body = {}
        //body.CalendarIds = [+dateItem.id];
        //body.IncludeRecurrences = true;
        //if (dateItem.isLocalDbEvent) {
        //    await getLocalCalenderEventById(dateItem.id);
        //}
        //else {
        //    await getWhiseCalenderEventById(body);
        //}
        //if (eventExist) {
        //    let dateItem = JSON.parse(e.target.getAttribute("event"));
        //    setSelectedSlot(dateItem);
        //} else {
        //    alert(selectedLanguageTranslations != null ? selectedLanguageTranslations["alertSlotAlreadyBooked"] : "This Slot is already booked and not available please try an Other slot");
        //}
    }

    const getLocalCalenderEventById = async (id) => {

        let url = variables.API_URL + `LocalEvents/GetLocalEventById?clientId=${clientId}&officeId=${officeId}&eventId=${id}`;
        const config = {
            headers: {
                'Content-Type': 'application/json'
            },
        };

        let response = await axios.post(url, {}, config);

        if (response.data && response.data.calendars) {
            eventExist = true;
        }
        else {
            eventExist = false;
        }
    }

    const getToBeConfirmedIds = (purposeId) => {
        if (tobeConfirmedIds != undefined) {
            if (purposeId == 1) {
                return tobeConfirmedIds[0];
            }
            else if (purposeId == 2) {
                return tobeConfirmedIds[1];
            }
        }
    }

    const handlerResetSelectedSlot = () => {
        setSelectedSlot(null);
        if (freeSlotIds.length > 0) {
            let calendarActionIds = [freeSlotIds[0], freeSlotIds[1]];
            getWhiseCalenderEvents(calendarActionIds);
        }
    }

    useEffect(() => {
        if (freeSlotIds.length > 0 && clientToken != null) {
            let calendarActionIds = [freeSlotIds[0], freeSlotIds[1]];
            getWhiseCalenderEvents(calendarActionIds);
        }
    }, [freeSlotIds]);

    useEffect(() => {
        if (slotAvailable.length > 0 && selectedLanguage != null && selectedLanguageTranslations != null) {
            setAnnouncementMessage(selectedLanguageTranslations != null ? `${selectedLanguageTranslations["slotBookingAnnouncementSuitableDateTimeLabel"]}` : "Pick a slot that will suits you on");
        } else {
            setAnnouncementMessage(selectedLanguageTranslations != null ? `${selectedLanguageTranslations["slotBookingQuicklyAnnouncementSuitableDateTimeLabel"]}` : "Quickly book the next slot");
        }
    }, [slotAvailable])

    useEffect(() => {
        let dateItems = document.querySelectorAll(".datepicker-item");
        if (dateItems.length > 0 && whiseCalendarEvents.length > 0) {
            var daysPlaceholders = days;
            dateItems.forEach(item => {
                let currentYear = new Date().getFullYear();
                let month = item.parentElement.previousSibling.innerHTML;
                let date = item.childNodes[1].innerHTML;
                let day = item.childNodes[0].innerHTML.toLowerCase();

                //let date = new Date(month + "," + day + "," + currentYear);

                let currentDate = new Date().getDate();
                let currentTime = new Date().getTime();
                let events = whiseCalendarEvents;

                events.forEach(event => {
                    let eventDateTime = new Date(event.startDateTime);
                    let eventYear = eventDateTime.getFullYear();
                    let eventMonth = selectedLanguageTranslations[monthNames[eventDateTime.getMonth()]];
                    let eventDate = eventDateTime.getDate();
                    let eventDay = selectedLanguageTranslations[daysPlaceholders[eventDateTime.getDay()]].toLowerCase();

                    //let sliderDate = date.toDateString();
                    let eventTime = new Date(event.startDateTime).getTime();

                    //if ([...item.classList].includes('FC')) {
                    //    item.classList.remove("FC");
                    //}
                    item.classList.add(month + date);
                    if (eventDate == date && eventMonth.toLowerCase() == month.toLowerCase() && eventDay.toLowerCase() == day.toLowerCase() && eventTime > currentTime && date == currentDate) {
                        item.classList.add("has-event");
                        item.classList.add("current-day");
                    }
                    else if (eventDate == date && eventMonth.toLowerCase() == month.toLowerCase() && eventDay.toLowerCase() == day.toLowerCase() && eventTime > currentTime && date != currentDate) {
                        item.classList.add("has-event");
                    }
                    else if (date == currentDate) {
                        item.classList.add("current-day");
                    }
                })
            })
        }
    })

    useEffect(() => {
        if (yCoordinate != "" && xCoordinate != "") {
            let url = "https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-s-l+ff0000(" + yCoordinate + "," + xCoordinate + ")/" + yCoordinate + "," + xCoordinate + ",14/675x420?access_token=pk.eyJ1IjoibWluZGZyZWUiLCJhIjoiY2xkbzlvOXJjMDd2ZTNucXZ5cmJ2aW9weSJ9.-Qp-drHs8IPsY7Oo5WZXRA";
            setMapUrl(url);
        }
    }, [yCoordinate, xCoordinate])

    const formatPrice = (price, symbol) => {
        let formattedPrice = new Intl.NumberFormat('fr-BE', { style: 'currency', currency: 'EUR' }).format(price,);
        //let index = formattedPrice.indexOf(symbol);

        //let finalPrice = formattedPrice.substr(index+1)

        //return finalPrice + " " + symbol;
        return formattedPrice;
    }

    const clickHandleOpenBookSlotWindow = (e) => {
        if (e.target.nodeName == "p" || e.target.nodeName == "path" || e.target.nodeName == "svg") {
            document.getElementById("btnShowBookSlotWindow").classList.remove("d-block");
            document.getElementById("btnShowBookSlotWindow").classList.add("d-none");
        }
        else {
            e.target.classList.remove('d-block');
            e.target.classList.add('d-none');
        }
        document.getElementById("overlayDiv").classList.remove("d-none");
        document.getElementById("bookSlotForm").classList.remove("d-none", "d-md-none");
    }

    const handleCloseBookSlotWindow = () => {
        if (document.getElementById("btnShowBookSlotWindow") != null) {
            document.getElementById("bookSlotForm").classList.add("d-none", "d-md-none");
        }
        if (document.getElementById("btnShowBookSlotWindow") != null) {
            document.getElementById("btnShowBookSlotWindow").classList.remove("d-none");
            document.getElementById("btnShowBookSlotWindow").classList.add("d-block");
        }
        if (document.getElementById("overlayDiv") != null) {
            document.getElementById("overlayDiv").classList.add("d-none");
        }
    }

    useEffect(() => {
        if (isMobile == false) {
            handleCloseBookSlotWindow();
        }
    }, [isMobile])

    return (
        <>
            <div className={`overlay-div d-none`} id="overlayDiv"></div>
            {
                propertyId != undefined ?
                    <div className={`${isMobile == true ? "container-fluid" : "container"} p-3`}>
                        <div className="row align-items-center mb-4">
                            <div className="col-12">
                                <div className="d-flex justify-content-end">
                                    <div className="d-flex align-items-center ms-5 position-relative language-dropdown-container bg-white">
                                        <img src={languageDropdownIcon} className="position-absolute ps-2 start-0 language-dropdown-icon" />
                                        <select className="form-select fs-1-1 bg-transparent" aria-label="Default select example" value={selectedLanguage ? selectedLanguage : defaultLanguage} onChange={onChangeSelectLanguage}>
                                            {
                                                allLanguageForDropwon.length > 0 ?
                                                    allLanguageForDropwon.map((item) => {
                                                        return (
                                                            <option key={item.id} value={item.languageCode}> {item.languageCode}</option>
                                                        )
                                                    })
                                                    :
                                                    <></>
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row flex-sm-row-reverse">
                            <div className="col-lg-4 mb-3 booking-section container-sm">
                                <div className="form-container d-none d-md-none d-lg-block d-xl-block d-xxl-block" id="bookSlotForm">
                                    <p className="mb-lg-0 d-flex justify-content-end"><img src={closeIcon} onClick={handleCloseBookSlotWindow} className="cursor-pointer d-lg-none d-xl-none d-xxl-none d-md-block d-sm-block" /></p>
                                    <div className="d-flex justify-content-between align-items-center mb-4">
                                        <h6 className="m-0">{selectedLanguageTranslations != null ? selectedLanguageTranslations["bookingWindowTitle"] : "Book a visit"}</h6>
                                        {
                                            clientSettings != null && clientSettings.logoUrl != undefined ?
                                                <img src={clientSettings.logoUrl} alt="logo" width="70px" />
                                                :
                                                <></>
                                        }
                                    </div>
                                    <div className="slot-announcement mb-4">
                                        <img src={megaphone} alt="megaphone" />
                                        <span className="announcement">{announcementMessage},
                                            <span className="today-time">
                                                {
                                                    whiseCalendarEvents.length > 0 ? whiseCalendarEvents.map((item, i) => {
                                                        let selectedDate = date;
                                                        const daysNamesForTranslationKeys = days;
                                                        const monthNamesForTranslationKeys = ["january", "february", "march", "april", "may", "june",
                                                            "july", "august", "september", "october", "november", "december"
                                                        ];
                                                        let itemDate = new Date(item.startDateTime);

                                                        //bypass past events 
                                                        if (itemDate >= selectedDate) {

                                                            if (firstMatchFound == false && itemDate.getDate() == selectedDate.getDate()) {
                                                                let dayIndex = itemDate.getDay();
                                                                //if (dayIndex === new Date().getDay() && itemDate.getDate() === new Date().getDate()) {
                                                                //    dayIndex = 7;
                                                                //}
                                                                let monthDate = selectedDate.getDate();
                                                                let monthIndex = selectedDate.getMonth();
                                                                firstMatchFound = true;
                                                                return (
                                                                    <span className="ms-1" key={i}>
                                                                        {selectedLanguageTranslations[daysNamesForTranslationKeys[dayIndex] + "FullDayName"] + " " + monthDate + " " + selectedLanguageTranslations[monthNamesForTranslationKeys[monthIndex]] + "!"}
                                                                    </span>
                                                                )
                                                            }
                                                            else if (firstMatchFound == false) {
                                                                let dayIndex = itemDate.getDay();
                                                                //if (dayIndex === new Date().getDay() && itemDate.getDate() === new Date().getDate()) {
                                                                //    dayIndex = 7;
                                                                //}
                                                                let monthDate = itemDate.getDate();
                                                                let monthIndex = itemDate.getMonth();
                                                                let hours = itemDate.getHours() < 10 ? "0" + itemDate.getHours() : itemDate.getHours();
                                                                let minutes = itemDate.getMinutes() < 10 ? "0" + itemDate.getMinutes() : itemDate.getMinutes();
                                                                var time = hours + ":" + minutes;
                                                                firstMatchFound = true;
                                                                return (
                                                                    <span className="ms-1" key={i}>
                                                                        {selectedLanguageTranslations[daysNamesForTranslationKeys[dayIndex] + "FullDayName"] + " " + monthDate + " " + selectedLanguageTranslations[monthNamesForTranslationKeys[monthIndex]] + " at " + time + "!"}
                                                                    </span>
                                                                )
                                                            }
                                                        }
                                                    })
                                                        :
                                                        <></>
                                                }
                                            </span>
                                        </span>
                                    </div>
                                    <div className="d-flex align-items-center mb-2 position-relative">
                                        <button className="btn-datepicker-nav-left" onClick={scrollLeft}><img src={angleLeft} width="45" /></button>
                                        <Datepicker
                                            locale={locale}
                                            onChange={onSelectedDay}
                                            startValue={date}
                                            ref={ref}
                                            classNames={{ dayLabel: "week-days", dayItem: "datepicker-item", monthLabel: "months" }}
                                        />
                                        <button className="btn-datepicker-nav-right" onClick={scrollRight}><img src={angleRight} width="45" /></button>
                                    </div>
                                    <p className="hour-heading mb-2">{selectedLanguageTranslations != null ? selectedLanguageTranslations["bookingAvailableSlotsTitle"] : "Available slots"}</p>
                                    <div className="align-items-center mb-4">
                                        {
                                            whiseCalendarEvents.length > 0 ?
                                                whiseCalendarEvents.map((item, i) => {
                                                    let selectedDate = date;
                                                    let itemDate = new Date(item.startDateTime);

                                                    if (itemDate >= selectedDate && selectedDate.getDate() == itemDate.getDate() && selectedDate.getMonth() == itemDate.getMonth()) {
                                                        let hours = itemDate.getHours() < 10 ? "0" + itemDate.getHours() : itemDate.getHours();
                                                        let minutes = itemDate.getMinutes() < 10 ? "0" + itemDate.getMinutes() : itemDate.getMinutes();
                                                        var time = hours + ":" + minutes;
                                                        return (
                                                            <button className={`available-hour mb-0 me-1 ${selectedSlot != null && selectedSlot.id == item.id ? "active" : ""}`}
                                                                key={i} onClick={handlerSelectedSlot}
                                                                event={JSON.stringify(item)}>
                                                                {time}
                                                            </button>
                                                        )
                                                    }
                                                })
                                                :
                                                <></>
                                        }
                                    </div>
                                    {
                                        selectedSlot == null ?
                                            <button type="button" className="site-btn w-100 site-btn-padd disabled" disabled>{selectedLanguageTranslations != null ? selectedLanguageTranslations["slotBookingBtn"] : "Book a Slot"}</button>
                                            :
                                            <button type="button" className="site-btn w-100 site-btn-padd" onClick={handlerBookSlot}>
                                                {
                                                    showButtonLoader ?
                                                        <>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            <span class="visually-hidden">Loading...</span>
                                                        </>
                                                        :
                                                        selectedLanguageTranslations != null ? selectedLanguageTranslations["slotBookingBtn"] : "Book a Slot"
                                                }
                                            </button>
                                    }
                                </div>
                                <button className="site-btn w-100 site-btn-padd booking-float-btn d-lg-none d-xl-none d-xxl-none d-block" id="btnShowBookSlotWindow" onClick={clickHandleOpenBookSlotWindow}>
                                    <FontAwesomeIcon icon={faChevronUp} /><br></br>
                                    {selectedLanguageTranslations != null ? selectedLanguageTranslations["slotBookingBtn"] : "Book a Slot"}
                                </button>
                            </div>
                            <div className="col-lg-8">
                                <div className="property-page-container bg-white mb-5">
                                    <div className="property-detail-container">
                                        <p className='property-detail-label d-inline-block fs-1-1 text-center mb-3'>{selectedLanguageTranslations != null ? selectedLanguageTranslations["bookingSiteAgencyLabel"] : "Agency"}</p>
                                        <div className="row align-items-center">
                                            <div className="col-sm-12 col-md-4 col-lg-4">
                                                {
                                                    clientSettings != null && clientSettings.logoUrl != undefined ?
                                                        <img src={clientSettings.logoUrl} className="main-logo mb-sm-3 mb-md-0 mb-lg-0 mb-xl-0 mb-xxl-0 mb-3" alt="Logo" />
                                                        :
                                                        <></>
                                                }
                                            </div>
                                            <div className="col-sm-12 col-md-8 col-lg-8">
                                                <h4 className="heading mb-3">{whiseOffice != null && whiseOffice.name !== undefined ? whiseOffice.name : ""}</h4>
                                                <div className="d-flex align-items-center mb-3">
                                                    <img src={locationPin} alt="Location" />
                                                    {
                                                        whiseOffice != null ? <h5 className="fs-1-1 ms-2 mb-0">{(whiseOffice.address1 !== undefined ? whiseOffice.address1 + " " : "") + (whiseOffice.number !== undefined ? whiseOffice.number + ", " : "") + (whiseOffice.zip != undefined ? whiseOffice.zip + " " : "") + (whiseOffice.city != undefined ? whiseOffice.city : "")}</h5> : <h5 className="ms-2"></h5>
                                                    }
                                                </div>
                                                <div className="d-flex align-items-center mb-3">
                                                    <img src={phone} alt="phone icon" />
                                                    <h5 className="fs-1-1 ms-2 mb-0">{whiseOffice != null && whiseOffice.telephone != undefined ? whiseOffice.telephone : ""}</h5>
                                                </div>
                                                <div className="d-flex align-items-center mb-3">
                                                    <img src={websiteIcon} alt="phone icon" />
                                                    <h5 className="fs-1-1 ms-2 mb-0">{whiseOffice != null && whiseOffice.website != undefined ? whiseOffice.website : ""}</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="property-page-label fs-1-1 text-center mt-3">{selectedLanguageTranslations != null ? selectedLanguageTranslations["bookingSiteVisitRequestPropertyLabel"] : "Visit request page for property"}</p>
                                </div>
                                <div className="main-img mb-5">
                                    <img src={selectedEstate != null && selectedEstate.pictures != undefined && selectedEstate.pictures.length > 0 ? selectedEstate.pictures[0].urlLarge : propMainImg}
                                        className="rounded-4" width="100%" alt="house image" />
                                </div>
                                <div className="form-container d-flex justify-content-between align-items-center position-relative mb-4">
                                    <div>
                                        <h4 className="heading mb-0 mt-2">{selectedEstate != null ? selectedEstate.name : ""}</h4>
                                        <p className="sab-heading mb-2">{selectedEstate != null ? formatPrice(selectedEstate.price, selectedEstate.currency) : 0}</p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div className="border-end pe-4 room-products">
                                            <img src={bed} alt="bed" className={`${selectedEstate != null && selectedEstate.bedRooms != undefined ? "me-1" : ""}`} />  {selectedEstate != null && selectedEstate.bedRooms != undefined ? selectedEstate.bedRooms : ""}
                                        </div>
                                        <div className="border-end px-4 room-products">
                                            <img src={p_shower} alt="shower" className={`${selectedEstate != null && selectedEstate.bathRooms != undefined ? "me-1" : ""}`} /> {selectedEstate != null && selectedEstate.bathRooms != undefined ? selectedEstate.bathRooms : ""}
                                        </div>
                                        <div className="ps-4 room-products">
                                            <img src={lift} alt="lift" className={`${selectedEstate != null && selectedEstate.area != undefined ? "me-1" : ""}`} /> {selectedEstate != null && selectedEstate.area != undefined ? <>{selectedEstate.area}<span className="ms-05">m</span><sup>2</sup></> : ""}
                                        </div>
                                    </div>
                                    {
                                        estatePurpose != null ?
                                            <div className="position-absolute text-center sale-tag">
                                                {
                                                    estatePurpose.name
                                                }
                                            </div>
                                            :
                                            <></>
                                    }
                                </div>
                                <div className="d-flex justify-content-between mb-4 prop-images">
                                    {
                                        selectedEstate != null && selectedEstate.pictures != undefined && selectedEstate.pictures.length > 0 ? selectedEstate.pictures.map((item, i) => {
                                            return (
                                                <img src={item.urlLarge} alt="" className="room-image me-2" key={i} />
                                            )
                                        })
                                            :
                                            <></>
                                    }
                                </div>
                                <div className="form-container mb-4">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="heading">{selectedLanguageTranslations != null ? selectedLanguageTranslations["bookingEstateDescription"] : "Description"}</h4>
                                        {
                                            whiseOffice != null && whiseOffice.website != undefined ?
                                                <a href={whiseOffice.website} className="url-link" target="_blank">{whiseOffice.website}</a>
                                                :
                                                <></>
                                        }
                                    </div>
                                    <p className="text-paragraph">
                                        {
                                            selectedEstate != null && selectedEstate.shortDescription != undefined && selectedEstate.shortDescription.length > 0
                                                ?
                                                selectedEstate.shortDescription.map((item, i) => {
                                                    const markup = { __html: item.content };
                                                    return (
                                                        <p className="mb-0" dangerouslySetInnerHTML={markup} key={i}></p>
                                                    )
                                                })
                                                :
                                                ""
                                        }
                                    </p>
                                </div>
                                <div className="form-container">
                                    {
                                        mapUrl != "" ?
                                            <iframe
                                                width="100%"
                                                height="420px"
                                                src={mapUrl}
                                                title="Streets"
                                            ></iframe>
                                            :
                                            <></>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div></div>
            }
            <Footer />
            <EventModal showModal={showModal} handleClose={handleClose} clientToken={clientToken} eventSlot={selectedSlot}
                estate={selectedEstate} getAction={getAction} callbackGetToBeConfirmedIds={getToBeConfirmedIds}
                calendarActions={calendarActions} parent={getOfficeSettings} resetSelectedSlot={handlerResetSelectedSlot}
                officeId={officeId} clientId={clientId} clientSettings={clientSettings} time={time} formStep={formStep}
                setFormStep={setFormStep} prevSelectedEventSlot={prevSelectedEventSlot} selectedLanguage={selectedLanguage}
                selectedLanguageTranslations={selectedLanguageTranslations} agentLanguageTranslation={agentLanguageTranslation}
            />
        </>
    )
}
